import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  styled,Grid,Badge
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { mailIcon,bellIcon } from "./assets";
import AdvancedSearch from "../../advancedsearch/src/AdvancedSearch.web";
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={this.props.theme ? darkTheme : lightTheme}>
        <MainContainer data-test-id="mainContainer" style={{paddingLeft: this.props.open ? 190 : 50}}>
        <NavBar>
              <Grid container spacing={4}>
                <NavGrid item md={4} xs={4}>
                  <AdvancedSearch navigation={undefined} id={""} />
                </NavGrid>
                <NavGrid item md={4} lg={4}>
                  <NavLinkBox>
                    { (!this.state.dashboardTab) ? <NavLinkTypography onClick={this.handleDashboardTab} data-test-id="dashboardTabTest">Dashboard</NavLinkTypography>
                    : <NavLinkActiveTypography onClick={this.handleDashboardTab} data-test-id="dashboardActiveTest">Dashboard</NavLinkActiveTypography>
                  }
                    {(this.state.courseTab)? <NavLinkActiveTypography onClick={this.handleCourseTab} data-test-id="courseTabActiveTest">Courses</NavLinkActiveTypography>
                    :<NavLinkTypography onClick={this.handleCourseTab} data-test-id="courseTabTest">Courses</NavLinkTypography>}<NavLinkTypography>Community</NavLinkTypography>
                  </NavLinkBox>
                </NavGrid>
                <NavGrid item>
                  <ProfileBox>
                    <BadgeContainer badgeContent={0} color="secondary">
                      <img src={mailIcon} style={{width:"24px",height:"24px"}}/>
                    </BadgeContainer>
                    <BadgeContainer badgeContent={0} color="secondary">
                      <img src={bellIcon}  style={{width:"24px",height:"24px"}}/>
                    </BadgeContainer>
                    <ProfileImageNameBox>
                      <img src={this.getProfileImage()} style={{width:"40px",height:"40px"}}/>
                      <NameBox>
                        <NameTypography data-test-id="nametest">{this.state.profileData.attributes.preferred_name}</NameTypography>
                        <PositionTypography data-test-id="roletest">{this.state.profileData.attributes.role_id}</PositionTypography>
                      </NameBox> 
                      <ArrowDropDownRounded style={{color:"#1F1F1F"}}/> 
                    </ProfileImageNameBox>
                  </ProfileBox>
                </NavGrid>
              </Grid>
            </NavBar>
        </MainContainer>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const MainContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  width:"100%",
  background: theme.palette.background.default,
  height: 'inherit',
  '& .MuiPaper-root': {
      backgroundColor: theme.palette.primary.main
  }
}))
const NavBar = styled(Box)(({ theme }) => ({
  width: "83%",
  height: "75px",
  padding: "15.79px 36.45px 15.79px 33.5px",
  borderRadius: "18px",
  border:"1px",
  background: theme.palette.background.paper,
  margin:"48px 26px",
}))

const NavGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center"
}))
const ProfileBox = styled(Box)(({ theme }) => ({
  display: "flex", flexDirection: "row", gap: "8px"
}))
const ProfileImageNameBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px"
}))
const NameBox = styled(Box)(({ theme }) => ({

}))
const NameTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "18px",
  textAlign: "left",
  color: theme.palette.info.main
}))
const PositionTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "18px",
  textAlign: "left",
  color: theme.palette.info.light,
  textTransform:"capitalize",
}))
const NavLinkBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "12px",
  alignItems: "center",
  justifyContent: "center"
}))
const NavLinkTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "16px",
  textAlign: "left",
  color: theme.palette.text.secondary,
  "& .active":{
    borderBottom: `2px solid ${theme.palette.text.secondary}`,
    padding: "8px",
    gap: "10px",
    borderRadius: "0px 0px 8px 8px"
  }
}))
const NavLinkActiveTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "16px",
  textAlign: "left",
  color: theme.palette.action.selected,
  borderBottom: `2px solid ${theme.palette.action.selected}`,
  padding: "8px",
  gap: "10px",
  borderRadius: "0px 0px 8px 8px"
}))
const BadgeContainer = styled(Badge)(({ theme }) => ({
  width: "44px",
  height: "44px",
  padding: "10px",
  gap: "4px",
  borderRadius: "8px",
  opacity: "0px",
}))
const FontStyle = {
  fontFamily: 'Rubik',
  h1: { fontSize: '32px' },
  h2: { fontSize: '30px' },
  h3: { fontSize: '28px' },
  h4: { fontSize: '26px' },
  h5: { fontSize: '24px' },
  h6: { fontSize: '22px' },
  subtitle1: { fontSize: '20px' },
  subtitle2: { fontSize: '18px' },
  body1: { fontSize: '16px' },
  body2: { fontSize: '14px' }
}
export const lightTheme = createTheme({
  palette: {
      primary: {
          main: '#FFFFFF',
          light:"010101"
      },
      secondary: {
          main: '#E2E8F0',
          light:"#D1FAE5",
          dark:"#0F172A"
      },
      background: {
          default: '#FFFFFF',
          paper: "#EDF1F6"
      },
      text: {
          primary: '#334155',
          secondary: '#475569',
          hint: '#0F172A',
      },
      action: {
          active: '#F1F5F9',
          selected: '#1A469C',
          focus: '#059669',
          disabled: '#475569'
      },
      info: {
          main: "#21272A",
          light: '#848484',
          dark: '#CBD5E1',
          contrastText:'#1A469C'
      },
  },
  typography: FontStyle
});
export const darkTheme = createTheme({
  palette: {
      primary: {
          main: '#161616',
          light:"#BDCFF4"
      },
      secondary: {
          main: '#18212E',
          light:"#074525",
          dark:"#BDCFF4"
      },
      background: {
          default: '#000000',
          paper: "#121B29",
      },
      text: {
          primary: '#D5DDF0',
          secondary: '#93A1B5',
          hint: '#D5DDF0'
      },
      action: {
          active: '#70A1FF',
          selected: '#70A1FF',
          focus: '#42EBB5',
          disabled: '#99A7BB'
      },
      info: {
          main: "#D5DDF0",
          light: '#99A7BB',
          dark: '#273444',
          contrastText:'#12326F'
      },
  },
  typography: FontStyle
});
// Customizable Area End
