import React from "react";
// Customizable Area Start
import {
  ScrollView,
  SafeAreaView,
  StyleSheet,
} from "react-native";
import PostCreationController, { Props } from "./PostCreationController.web";
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { All, allBlack, group, groupBlack, lightVerify, darkVerify, addFileIcon } from "../src/assets";
import HamburgerMenu from "../../hamburgermenu/src/HamburgerMenu.web";
import { ThemeProvider } from "@material-ui/core/styles";
import { BackButton, NextButton, SwitchDiv, darkTheme, lightTheme } from "../../email-account-registration/src/EmailAccountRegistration.web";
import { Box, Button, Grid, Typography, styled, Input, Menu, FormControlLabel, Switch } from "@material-ui/core";
import { DatePicker, Radio, Select } from "antd";
import { EditHeading, FieldModal, ModalHeading, ModalLine, ModalSubHead } from "../../customisableuserprofiles/src/CustomisableUserProfiles.web";
import { ToastContainer } from "react-toastify";
import Loader from "../../../components/src/Loader.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { CloseDiv } from "../../bulletinboard/src/BulletinBoard.web";
import moment from "moment";
import { deleteIcon, editIcon } from "../../customisableuserprofiles/src/assets";
const ConfigJSON = require('./config.js')
// Customizable Area End

export default class PostCreation extends PostCreationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  publishModal = () => (
    <FieldModal
      open={this.state.publishModal}
      onCancel={this.closeModals}
      title={
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <ModalHeading variant="h5">Publish</ModalHeading>
          <ModalLine style={{ width: '150px' }} />
          <ModalSubHead variant="subtitle2">Publish info</ModalSubHead>
          <ModalLine style={{ width: '150px' }} />
        </div>}
      centered
      closable={true}
      footer={null}
      destroyOnClose
      width={769}
      style={{ paddingBottom: '40px' }}
    >
      <Radio.Group
        data-test-id='changePublishType'
        value={this.state.publishType}
        onChange={this.changePublishType}
        style={{ width: "100%" }}
      >
        <div style={{ display: 'flex' }}>
          <ArrowBackIosIcon style={{ marginRight: "200px", marginLeft: '20px' }} onClick={this.closeModals} />
          <ModalHeading variant="h5">where to publish</ModalHeading>
        </div>
        <FormControlLabel
          value='all_companies'
          control={<Radio />}
          label={<RadioDiv>
            <img src={this.state.darkTheme ? allBlack : All} />
            <div>
              <RadioOptionHead>All</RadioOptionHead>
              <RadioOptionSubHead>The reminder will be sent to all companies “worker”.</RadioOptionSubHead>
            </div>
          </RadioDiv>}
          labelPlacement="start"
        />
        <FormControlLabel
          value='all_groups'
          control={<Radio />}
          label={<RadioDiv>
            <img src={this.state.darkTheme ? groupBlack : group} />
            <div>
              <RadioOptionHead>All Groups</RadioOptionHead>
              <RadioOptionSubHead>The Notification will be sent to all groups of all worker.</RadioOptionSubHead>
            </div>
          </RadioDiv>}
          labelPlacement="start"
        />
        <FormControlLabel
          value='specific_groups'
          control={<Radio />}
          label={<RadioDiv>
            <img src={this.state.darkTheme ? groupBlack : group} />
            <div>
              <RadioOptionHead>Specific Groups</RadioOptionHead>
              <RadioOptionSubHead>The Notification will be sent to specific groups.</RadioOptionSubHead>
              <RadioOptionSubHead>Ibrahim, Ibrahim1 and 3 others</RadioOptionSubHead>
            </div>
          </RadioDiv>}
          labelPlacement="start"
        />
      </Radio.Group>
      <BackButton data-test-id="closeModalButton" onClick={this.closeModals}>Back</BackButton>
      <NextButton
        data-test-id="addCourseButton"
        style={{ width: '65%', marginLeft: '10px', color: '#FFF' }}
        onClick={this.addNewCourse}
        disabled={!this.state.publishType}
      >
        Send
      </NextButton>
    </FieldModal>
  )

  successModal = () => (
    <FieldModal
      data-test-id="successModal"
      open={this.state.newChapterAdded}
      onCancel={this.closeSuccessModal}
      centered
      closable={false}
      footer={null}
      destroyOnClose
      width={696}
      style={{ height: '430px' }}
    >
      <div style={{ display: 'flex', justifyContent: "center", padding: '20px', flexDirection: 'column', alignItems: 'center' }}>
        <img src={this.state.darkTheme ? darkVerify : lightVerify} alt="verificationModal" style={{ height: '176px' }} />
        <EditHeading style={{ textAlign: 'center' }}>Chapter has been created Successfully</EditHeading>
      </div>
      <NextButton
        data-test-id="newChapterAddedButton"
        onClick={() => { this.closeSuccessModal(); this.setState({ enableListChapter: true, enableChapter: false }) }}
        fullWidth
        style={{ color: '#F8FAFC' }}
      >
        Done
      </NextButton>
    </FieldModal>
  )

  checkMedia = () => {
    const open = Boolean(this.state.anchorEl);
    if (this.state.imagePreview && this.state.videoPreview && this.state.uploadDoc) {
      return <div style={{ display: 'flex', justifySelf: 'center', flexDirection: 'column', width: "100%", maxHeight: '148px' }}>
        <div style={{ display: "flex", maxHeight: "106px" }}>
          <img style={{ width: '50%', height: '100%', borderRadius: '20px' }} src={this.state.imagePreview} />
          <video style={{ width: '50%', height: '100%', borderRadius: '20px' }} src={this.state.videoPreview} />
        </div>
        <FileViewDiv >
          <MenuLines />
          <div>
            <FileNameTxt>{this.state.uploadDoc.name}</FileNameTxt>
            <FileSizeTxt>{(this.state.uploadDoc.size / 1024).toFixed(0)}{' KB'}</FileSizeTxt>
          </div>
          <CloseIcon data-test-id="removeFile" style={{ width: '16px' }} onClick={() => this.setState({ uploadDoc: null })} />
        </FileViewDiv>
      </div>
    } else if (this.state.imagePreview && this.state.videoPreview) {
      return <div style={{ display: 'flex', justifySelf: 'center', flexDirection: 'column', width: "100%", maxHeight: '148px' }}>
        <div style={{ display: "flex", maxHeight: "106px" }}>
          <img style={{ width: '50%', height: '100%', borderRadius: '20px' }} src={this.state.imagePreview} />
          <video style={{ width: '50%', height: '100%', borderRadius: '20px' }} src={this.state.videoPreview} />
        </div>
        <div
          data-test-id="uploadMenu"
          aria-controls={open ? 'basic-menu' : undefined}
          id="basic-button"
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={(event: React.MouseEvent<HTMLDivElement>) => this.setState({ anchorEl: event.currentTarget })}
        >
          <AddCircleOutlineRoundedIcon style={{ marginRight: '10px', float: 'right' }} />
        </div>
      </div>
    } else if (this.state.imagePreview) {
      return <div style={{ display: 'flex', justifySelf: 'center', flexDirection: 'column', width: "100%", maxHeight: '148px' }}>
        <img style={{ width: '90%', height: '70%', borderRadius: '20px' }} src={this.state.imagePreview} />
        <div
          data-test-id="uploadMenu"
          aria-controls={open ? 'basic-menu' : undefined}
          id="basic-button"
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={(event: React.MouseEvent<HTMLDivElement>) => this.setState({ anchorEl: event.currentTarget })}
        >
          <AddCircleOutlineRoundedIcon style={{ marginRight: '10px', float: 'right' }} />
        </div>
      </div>
    } else if (this.state.videoPreview) {
      return <div style={{ display: 'flex', justifySelf: 'center', flexDirection: 'column', width: "100%", maxHeight: '148px' }}>
        <video style={{ width: '90%', height: '70%', borderRadius: '20px' }} src={this.state.videoPreview} />
        <div
          data-test-id="uploadMenu"
          aria-controls={open ? 'basic-menu' : undefined}
          id="basic-button"
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={(event: React.MouseEvent<HTMLDivElement>) => this.setState({ anchorEl: event.currentTarget })}
        >
          <AddCircleOutlineRoundedIcon style={{ marginRight: '10px', float: 'right' }} />
        </div>
      </div>
    } else if (this.state.uploadDoc) {
      return <div style={{ display: 'flex', justifySelf: 'center', flexDirection: 'column', width: "100%", maxHeight: '148px' }}>
        <FileViewDiv >
          <MenuLines />
          <div>
            <FileNameTxt>{this.state.uploadDoc.name}</FileNameTxt>
            <FileSizeTxt>{(this.state.uploadDoc.size / 1024).toFixed(0)} {" KB"}</FileSizeTxt>
          </div>
          <CloseIcon style={{ width: '16px' }} onClick={() => this.setState({ uploadDoc: null })} />
        </FileViewDiv>
        <div
          data-test-id="uploadMenu"
          aria-controls={open ? 'basic-menu' : undefined}
          id="basic-button"
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={(event: React.MouseEvent<HTMLDivElement>) => this.setState({ anchorEl: event.currentTarget })}
        >
          <AddCircleOutlineRoundedIcon style={{ marginRight: '10px', float: 'right' }} />
        </div>
      </div >
    } else {
      return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'end' }}
        data-test-id="uploadMenu"
        aria-controls={open ? 'basic-menu' : undefined}
        id="basic-button"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(event: React.MouseEvent<HTMLDivElement>) => this.setState({ anchorEl: event.currentTarget })}
      >
        <CloudUploadOutlinedIcon style={{ marginTop: '100px' }} />
        <UploadText>Upload media</UploadText>
      </div>
    }
  }

  renderSuggestionBox = () => {
    const { suggestions, showSuggestions, suggestionBoxPosition } = this.state;

    if (!showSuggestions) return null;

    return (
      <div
        style={{
          position: 'absolute',
          top: suggestionBoxPosition.top,
          left: suggestionBoxPosition.left,
          backgroundColor: 'white',
          border: '1px solid #ccc',
          borderRadius: '4px',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
          zIndex: 1000
        }}
      >
        <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
          {suggestions.map((sugg: any, index: any) => (
            <>
              {sugg?.suggestions.map((suggestion: any, i: any) => (
                <li
                  key={index}
                  style={{
                    padding: '8px 12px',
                    cursor: 'pointer',
                    borderBottom: '1px solid #eee'
                  }}
                  onMouseDown={() => this.handleSuggestionClick(suggestion)}
                >
                  {suggestion}</li>
              ))}
            </>
          ))}
        </ul>
      </div>
    );
  }

  renderStepper = (value: number) => (
    <div style={{ width: "100%", display: "flex" }}>
      {ConfigJSON.PostSteps.map((label: string) => (
        <TopMenuOption key={label}>
          {label}
        </TopMenuOption>
      ))}
    </div>
  )

  addCourse = () => (
    <Grid container spacing={2}>
      <LeftSection item xs={7}>
        <div>
          {this.renderStepper(16)}
          <CourseInfoSection>
            <CourseInfoTitle>Course Info</CourseInfoTitle>
            <hr style={{ width: '55%' }} />
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <CourseTitleDescription>Offline Download</CourseTitleDescription>
              <SwitchDiv style={{ marginTop: 0 }}><Switch /></SwitchDiv>
            </div>
          </CourseInfoSection>
        </div>
        <CourseDetailsSection>
          <CourseTitleDescription>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the prin
          </CourseTitleDescription>
          <SubHeading>Course Title</SubHeading>
          <InputField
            data-test-id="txtCourseTitle"
            value={this.state.CourseInfo['course_name']}
            onChange={(event) => this.changeCourseInfo('course_name', event.target.value)}
          />
          <SubHeading>Course Description</SubHeading>
          <div onDoubleClick={this.handleOpen} onContextMenu={this.handleSelectionChange}>
            {this.state.open && (
              <div style={{ position: 'fixed', left: 0, right: 0, top: 0, bottom: 0, background: '#00000050', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 99999999 }}
              >
                <div
                  style={{ width: 715, height: 404, background: 'white', borderRadius: "8px" }}
                >
                  <ReactQuill

                    ref={this.quillRef}
                    data-test-id="txtDescription2"
                    placeholder={"Enter your Description"}
                    value={this.state.CourseInfo['course_description'] && String(this.state.CourseInfo['course_description'])}
                    onChange={(event) => this.changeCourseInfo('course_description', event)}
                    onChangeSelection={this.handleSelectionChange}
                    formats={formats}
                    style={{ height: 347 }}
                    className="modalTextEditor"
                  />
                  {this.renderSuggestionBox()}
                  <div style={{ display: 'flex', gap: 10, justifyContent: 'flex-end', paddingBlock: 8, marginInline: 18, borderTop: '1px solid lightgray' }}>
                    <button onClick={this.handleClose} style={{ backgroundColor: "white", border: '1px solid #1A469C', borderRadius: 30, padding: '10px 16px', color: '#1A469C', fontWeight: 700, fontSize: 14, fontFamily: "Rubik" }}>Cancel</button>
                    <button onClick={this.handleClose} style={{ backgroundColor: "#1A469C", borderRadius: 30, padding: '10px 24px', border: 'none', color: 'white', fontWeight: 700, fontSize: 14, fontFamily: "Rubik" }}>Save</button>
                  </div>
                </div>
              </div>
            )}

            <ReactQuill
              ref={this.quillRef}
              data-test-id="txtDescription"
              value={this.state.CourseInfo['course_description'] && String(this.state.CourseInfo['course_description'])}
              formats={formats}
            />
          </div>
          <ImageVideoSection>
            <div style={{ width: "50%", marginRight: '10px' }}>
              <SubHeading>Cover Image</SubHeading>
              {this.state.coverPhotoPreview ?
                <UploadMediaDiv style={{ marginBottom: "10px" }}>
                  <img src={this.state.coverPhotoPreview} style={{ width: "100%", height: "100%", borderRadius: "15px" }} />
                  <CloseDiv
                    data-test-id='removeCoverImage'
                    style={{ left: "40%" }}
                    onClick={this.removeCoverImage}
                  />
                </UploadMediaDiv>
                : <label htmlFor="coverImage" className="profile-upload-btn">
                  <input
                    data-test-id="coverImageBtn"
                    id="coverImage"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={this.uploadCover}
                  />
                  <ImageUploadDiv>
                    <img src={addFileIcon} />
                    <div style={{ alignItems: 'center', display: "flex" }}>
                      <LessonTime style={{ fontSize: "10px" }}>
                        Drag and drop image , or
                      </LessonTime>
                      <AddModuleButton variant="text" style={{ border: "none", fontSize: "12px", width: "auto", margin: 0 }} >
                        browser
                      </AddModuleButton>
                    </div>
                  </ImageUploadDiv>
                </label>}
            </div>
            <div style={{ width: "50%", marginRight: '10px' }}>
              <SubHeading>Promotional Video</SubHeading>
              {this.state.promotionalVideoPreview ?
                <UploadMediaDiv style={{ marginBottom: "10px" }}>
                  <video src={this.state.promotionalVideoPreview} style={{ width: "100%", height: "100%", borderRadius: "15px" }} />
                  <CloseDiv
                    data-test-id='removePromotionalVideo'
                    style={{ left: "40%" }}
                    onClick={this.removePromotionalVideo}
                  />
                </UploadMediaDiv>
                : <label htmlFor="promotionalVideo" className="profile-upload-btn">
                  <input
                    data-test-id="promotionalVideoBtn"
                    id="promotionalVideo"
                    type="file"
                    accept="video/*"
                    style={{ display: "none" }}
                    onChange={this.uploadCover}
                  />
                  <ImageUploadDiv>
                    <img src={addFileIcon} />
                    <div style={{ display: "flex", alignItems: 'center' }}>
                      <LessonTime style={{ fontSize: "10px" }}>
                        Drag and drop video , or
                      </LessonTime>
                      <AddModuleButton variant="text" style={{ fontSize: "12px", border: "none", width: "auto", margin: 0 }} >
                        browser
                      </AddModuleButton>
                    </div>
                  </ImageUploadDiv>
                </label>}
            </div>
          </ImageVideoSection>
        </CourseDetailsSection>
      </LeftSection>

      <RightSection item xs={4}>
        <div style={{ display: 'flex' }}>
          <SubHeading>Category</SubHeading>
          <SubHeading style={{ fontSize: "8px" }}>(Required)</SubHeading>
        </div>
        <Select
          placeholder="select your category"
          data-test-id="txtCategory"
          value={this.state.CourseInfo['category']}
          onChange={(event) => this.changeCourseInfo('category_attributes', String(event))}
        >
          {this.state.allCategories?.map((item) => (
            <Select.Option value={item.attributes.id}>
              {item.attributes.name}
            </Select.Option>
          ))}
        </Select>
        <SubHeading>Sub-category</SubHeading>
        <Select
          data-test-id="txtSubCategory"
          placeholder="Enter your sub-category"
          value={this.state.CourseInfo['sub_category']}
          onChange={(event) => this.changeCourseInfo('sub_category', String(event))}
        >
          {this.state.allSubCategories?.map((item) => (
            <Select.Option value={item.attributes.id}>
              {item.attributes.name}
            </Select.Option>
          ))}
        </Select>
        <CourseContentTxt>Course Duration</CourseContentTxt>
        <SubHeading>start date</SubHeading>
        <DateField
          data-test-id='start_date'
          placement="bottomRight"
          format="MM-DD-YYYY"
          showToday={false}
          onChange={(event) => this.changeCourseInfo('start_date', String(event))}
          defaultValue={this.state.CourseInfo['start_date'] ? moment(this.state.CourseInfo['start_date']?.toString()) : undefined}
        />
        <SubHeading>end date</SubHeading>
        <DateField
          data-test-id='end_date'
          placement="bottomRight"
          format="MM-DD-YYYY"
          showToday={false}
          onChange={(event) => this.changeCourseInfo('end_date', String(event))}
          defaultValue={this.state.CourseInfo['end_date'] ? moment(this.state.CourseInfo['end_date']?.toString()) : undefined}
        />
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <TagSubHeading>Tag Name</TagSubHeading>
          <InputField
            data-test-id="txtTags"
            placeholder="Enter tag name"
            style={{ borderRadius: "98px", width: "60%" }}
            value={this.state.CourseInfo['tags']}
            onChange={(event) => this.changeCourseInfo('tags', event.target.value)}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <TagSubHeading>Tag Color</TagSubHeading>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <div className={this.state.selectedTagColor === "#059669" ? "selected" : ""} style={{ borderRadius: "50%", margin: "5px" }}>
              <TagColor
                data-test-id="selectedGreen"
                style={{ background: "#059669" }}
                onClick={() => this.chooseTagColor("#059669")}
              />
            </div>
            <div className={this.state.selectedTagColor === "#D97706" ? "selected" : ""} style={{ borderRadius: "50%", margin: "5px" }}>
              <TagColor
                data-test-id="selectedOrange"
                style={{ background: "#D97706" }}
                onClick={() => this.chooseTagColor("#D97706")}
              />
            </div>
            <div className={this.state.selectedTagColor === "#B00606" ? "selected" : ""} style={{ borderRadius: "50%", margin: "5px" }}>
              <TagColor
                data-test-id="selectedRed "
                style={{ background: "#B00606" }}
                onClick={() => this.chooseTagColor("#B00606")}
              />
            </div>
          </div>
        </div>
        <NextButton
          data-test-id='btnContinue'
          onClick={() => this.setState({ addCurriculum: true, addNewCourse: false, enableChapter: true })}
          style={{ width: "100%" }}
        >
          continue
        </NextButton>
      </RightSection>
    </Grid>
  )

  addCurriculum = () => (
    <Grid container spacing={2}>
      {this.state.enableListChapter &&
        <>
          <LeftSection item xs={8}>
            <div>
              {this.renderStepper(16)}
              <CourseInfoSection>
                <CourseInfoTitle>Chapter list</CourseInfoTitle>
                <hr style={{ width: '65%' }} />
              </CourseInfoSection>
            </div>
            <CourseTitleDescription>
              Don’t worry you can easily rearranged the order of your content by clicking the lesson and
              drabbing it into your  desired location
            </CourseTitleDescription>
          </LeftSection>
          <ChapterSection>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <SubHeading>{this.state.ChapterInfo["chapter_name"]}</SubHeading>
              <div style={{ marginRight: "10px" }}>
                <Button
                  data-test-id="editCertificate"
                  variant="text"
                  style={{ color: '#1A469C', fontSize: '14px', textTransform: 'capitalize' }}
                  startIcon={<img src={editIcon} style={{ height: '16px' }} />}
                // onClick={() => this.EditCertificate(item.attributes)}
                >
                  Edit
                </Button>
                <Button data-test-id="deleteCertificate"
                  variant="text"
                  style={{ color: '#DC2626', fontSize: '14px', textTransform: 'capitalize' }}
                  // onClick={() => this.openDeleteModal(item.attributes.id, "certificate", "")}
                  startIcon={<img src={deleteIcon} style={{ height: '16px' }} />}
                >
                  Delete
                </Button>
              </div>
            </div>
            <div>

            </div>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <AddModuleButton
                data-test-id='addCurriculum'
                onClick={() => this.setState({ addCurriculum: true })}
                startIcon={<AddCircleOutlineRoundedIcon />}
                style={{ width: "auto", height: "32px", margin: 0 }}
              >
                Add Lesson
              </AddModuleButton>
              <CopyLessonButton
                data-test-id="copyLessonBtn"
                variant="text"
                startIcon={<AddCircleOutlineRoundedIcon />}
              >
                Copy lesson from
              </CopyLessonButton>
            </div>
          </ChapterSection>
          <div style={{ display: 'flex', justifyContent: "end" }}>
            <PublishButton
              style={{ height: "32px", width: "auto" }}
              data-test-id="addChapterBtn"
            >
              Add Chapter
            </PublishButton>
          </div>
        </>}
      {this.state.enableChapter && <LeftSection item xs={7}>
        <div>
          {this.renderStepper(16)}
          <CourseInfoSection>
            <CourseInfoTitle>Add Chapter</CourseInfoTitle>
            <hr style={{ width: '65%' }} />
          </CourseInfoSection>
        </div>
        <CourseDetailsSection>
          <CourseTitleDescription>
            Don’t worry you can easily rearranged the order of your content by clicking the lesson and
            drabbing it into your  desired location
          </CourseTitleDescription>
          <div style={{ marginLeft: "10px" }}>
            <CourseInfoSection>
              <CourseInfoTitle>New Chapter</CourseInfoTitle>
              <hr style={{ width: '60%' }} />
            </CourseInfoSection>
          </div>
          <SubHeading>Title</SubHeading>
          <InputField
            data-test-id="txtChapterTitle"
            placeholder="Enter chapter title"
            onChange={(event) => this.changeChapterInfo('chapter_name', event.target.value)}
            value={this.state.CourseInfo['course_name']}
          />
          <SubHeading>Add Lesson Title</SubHeading>
          <InputField
            data-test-id="txtLessonTitle"
            placeholder="Enter lesson title"
            onChange={(event) => this.changeChapterInfo('lesson_name', event.target.value)}
            value={this.state.CourseInfo['lesson_name']}
          />
        </CourseDetailsSection>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <AddModuleButton
            className="cancelChapterTitle"
            data-test-id="publishPost"
            onClick={this.openPublishModal}
            style={{ marginTop: "10px", width: "40%" }}
          >
            Cancel
          </AddModuleButton>
          <PublishButton
            data-test-id='addChapterTitle'
            onClick={() => this.setState({ newChapterAdded: true })}
            style={{ marginTop: "10px", width: "40%" }}
          >
            Create
          </PublishButton>
        </div>
      </LeftSection>}
      {this.publishModal()}
      {this.successModal()}
    </Grid>
  )
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <SafeAreaView style={styles.safeAreaView}>
        <ScrollView>
          {/* Customizable Area Start */}
          <ThemeProvider theme={this.state.darkTheme ? darkTheme : lightTheme}>
            <Loader loading={this.state.loading} />
            <HamburgerMenu navigation={this.props.navigation} handleMenu={this.leftMenuHandle} open={this.state.openLeftMenu} handleTheme={this.handleTheme} themeDark={this.state.darkTheme} />
            <NavigationMenu id="" navigation={this.props.navigation} open={this.state.openLeftMenu} theme={this.state.darkTheme} />
            <MainContainer container spacing={4} style={{ paddingLeft: this.state.openLeftMenu ? 273 : 150 }}>
              {this.state.addNewCourse && this.addCourse()}
              {this.state.addCurriculum && this.addCurriculum()}
              <ToastContainer containerId='A' />
            </MainContainer>
          </ThemeProvider >
          {/* Customizable Area End */}
        </ScrollView>
      </SafeAreaView>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
    alignSelf: "center",
    width: "100%"
  }
})
const MainContainer = styled(Grid)(({ theme }) => ({
  paddingTop: '10px',
  height: '100%',
  background: theme.palette.background.default,
  paddingRight: 48,
  paddingBottom: 50,
  transition: 'all 100ms linear',
  '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
    borderBottom: 'transparent'
  },
  '& .MuiInput-underline::after': {
    borderBottom: 'transparent'
  },
  "& .MuiInput-underline::before": {
    borderBottom: 'transparent'
  },
  '& .MuiSvgIcon-root': {
    fill: '#94A3B8'
  },
  "& .ant-select": {
    width: "auto",
    height: '38px',
    fontSize: '14px',
    fontFamily: 'Rubik',
    lineHeight: '18px',
    fontWeight: 400,
    color: theme.palette.secondary.main,
  },
  "& .ant-select:not(.ant-select-customize-input) .ant-select-selector": {
    minWidth: '91px',
    borderRadius: "8px",
    padding: '4px 12px',
    height: '38px',
    borderColor: theme.palette.info.dark,
    background: theme.palette.background.default
  },
  "& .ant-select-dropdown": {
    fontFamily: 'Rubik',
    width: 'auto !important',
    fontSize: '12px',
    fontWeight: 400,
    background: theme.palette.action.focus,
    minWidth: "200px",
  },
  "& .ant-select-arrow": {
    color: theme.palette.info.main
  },
  "& .ql-container": {
    borderRadius: '8px',
    height: "174px",
    border: 'none'
  },
  '& .quill': {
    position: 'relative',
    borderRadius: '8px',
    paddingBottom: 55,
    border: `1px solid ${theme.palette.info.dark}`,
    borderTop: `1px solid ${theme.palette.info.dark} !important`
  },
  "& .ql-toolbar": {
    borderRadius: '8px',
    border: `none`,
    position: "absolute",
    bottom: "10px",
    left: 10,
    width: "fit-content",
    transform: "translateY",
    zIndex: 1000,
    '& .ql-formats': {
      marginRight: 0
    },
    '& .ql-formats:first-child': {
      display: 'none'
    },
    '& .ql-formats button': {
      border: '1px solid #94A3B8',
      marginRight: 2
    },
  },
  "& .ql-editor": {
    background: theme.palette.background.default,
    color: theme.palette.secondary.main,
    borderRadius: 8,
    fontSize: '14px',
    fontFamily: 'Rubik'
  },
  "& .ql-editor.ql-blank::before": {
    color: theme.palette.info.light,
    fontStyle: 'normal',
    fontFamily: 'Rubik',
    fontSize: '14px'
  },
  "& .MuiPaper-root": {
    backgroundColor: theme.palette.primary.main,
  },
}))

const LeftSection = styled(Grid)(({ theme }) => ({
  display: 'flex',
  borderRadius: '18px',
  background: theme.palette.action.focus,
  marginRight: '10px',
  flexDirection: 'column'
}))

const ChapterSection = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  minHeight: "109px",
  border: `1px solid ${theme.palette.info.dark}`,
  borderRadius: "8px"
}))

const RightSection = styled(Grid)(({ theme }) => ({
  display: 'flex',
  borderRadius: '18px',
  background: theme.palette.action.focus,
  flexDirection: 'column',
  "& .publishBg:hover": {
    background: theme.palette.info.contrastText
  },
  "& .selected": {
    border: `1.5px solid ${theme.palette.info.contrastText}`
  }
}))

const CourseInfoSection = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  height: '38px'
})

const CourseTitleDescription = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '21px',
  textAlign: 'left',
  color: theme.palette.info.main
}))

const CourseInfoTitle = styled(Typography)(({ theme }) => ({
  fontSize: '32px',
  lineHeight: '37px',
  fontWeight: 500,
  color: theme.palette.secondary.main
}))

const SubHeading = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  lineHeight: "22px",
  fontWeight: 700,
  color: theme.palette.text.primary,
  marginTop: '5px'
}))

const TagSubHeading = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  lineHeight: "22px",
  fontWeight: 400,
  color: theme.palette.info.light,
  marginRight: "10px"
}))

const TagColor = styled(Box)(({ theme }) => ({
  width: "25px",
  height: "25px",
  borderRadius: "50%",
  margin: "3px",
  padding: "10px"
}))

const UploadMediaDiv = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '190px',
  borderRadius: '22px',
  border: `1px solid ${theme.palette.primary.main}`,
  boxShadow: '0px 8px 32px 0px #0000000F',
  flexDirection: 'column'
}))

const TitleLessonDiv = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '193px',
  padding: '36px 28px',
  borderRadius: 'px',
  border: `1px solid ${theme.palette.primary.main}`,
  boxShadow: '0px 8px 32px 0px #0000000F',
  marginTop: '10px'
}))

const AddModuleButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '45px',
  borderRadius: '30px',
  border: `1px solid ${theme.palette.info.contrastText}`,
  marginTop: '32px',
  textTransform: 'capitalize',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '22px',
  color: theme.palette.info.contrastText,
  '& .MuiSvgIcon-root': {
    fill: theme.palette.info.contrastText
  }
}))

const CopyLessonButton = styled(Button)(({ theme }) => ({
  height: '32px',
  textTransform: 'capitalize',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '22px',
  color: theme.palette.text.primary,
  '& .MuiSvgIcon-root': {
    fill: theme.palette.text.primary
  }
}))

const PublishButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '44px',
  borderRadius: '30px',
  marginTop: '10px',
  textTransform: 'capitalize',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '24px',
  background: theme.palette.info.contrastText,
  color: '#FFF'
}))

const InputField = styled(Input)(({ theme }) => ({
  width: '100%',
  height: '42px',
  gap: '8px',
  borderRadius: '8px',
  padding: '10px 12px',
  border: `1px solid ${theme.palette.info.dark}`,
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  textAlign: 'left',
  color: theme.palette.secondary.main,
  marginTop: '5px',
  background: theme.palette.background.default,
}))

const CategoryDiv = styled(Box)({
  display: 'flex',
  flexDirection: 'row'
})

const CourseDetailsSection = styled(Box)({
  maxHeight: '70vh',
  overflowY: 'scroll',
  marginTop: '30px',
  paddingRight: "20px",
  '& .modalTextEditor .ql-container.ql-snow': {
    height: '100%',
    border: 'none'
  }
})

const ImageVideoSection = styled(Box)({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  maxHeight: "271px"
})

const ImageUploadDiv = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  borderRadius: "22px",
  height: "190",
  border: `1px dashed ${theme.palette.secondary.contrastText}`
}))

const UploadText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '26px',
  color: theme.palette.text.secondary
}))

const CourseContentTxt = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: "26px",
  marginTop: '5px',
  color: theme.palette.info.main
}))

const MenuText = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.light,
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  marginLeft: '10px'
}))

export const RadioDiv = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row'
}))

export const RadioOptionHead = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '20px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.41px',
  color: theme.palette.info.main,
  marginLeft: '10px'
}))

export const RadioOptionSubHead = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '24px',
  color: theme.palette.info.light,
  marginLeft: '10px'
}))

const ModuleDiv = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  borderRadius: '18px',
  padding: '12px',
  display: 'flex',
  flexDirection: 'column'
}))

const AddContentButton = styled(Button)(({ theme }) => ({
  width: '145px',
  height: '44px',
  borderRadius: '30px',
  padding: '10px 16px',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  color: '#FFF',
  marginTop: '20px',
  background: theme.palette.action.selected,
  textTransform: 'capitalize',
  alignSelf: 'center'
}))

const LessonDiv = styled(Box)(({ theme }) => ({
  maxWidth: '304px',
  height: '42px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 11px'
}))

const LessonName = styled('span')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '22px',
  marginLeft: '10px'
}))

const LessonTime = styled('span')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '26px',
}))

export const FileNameTxt = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '20px',
}))

export const FileSizeTxt = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.light,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
}))

export const FileViewDiv = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: 'row',
  padding: '8px',
  justifyContent: 'space-around',
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '8px',
  alignItems: 'center'
}))

const UploadMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.background.default,
    boxShadow: `rgba(0, 0, 0, 0.15) 0px 2px 4px 0px`,
    borderRadius: '18px',
    border: `1px solid ${theme.palette.action.focus}`,
    width: '200px',
  },
  '& .MuiListItem-button:hover': {
    backgroundColor: theme.palette.warning.main
  }
}))

export const MenuLines = styled(MenuIcon)(({ theme }) => ({
  height: '42px',
  width: '42px',
  background: theme.palette.background.paper,
  padding: '12px',
  borderRadius: "6px"
}))

const DateField = styled(DatePicker)(({ theme }) => ({
  background: theme.palette.background.default,
  width: '100%',
  padding: '10px 12px',
  borderRadius: '8px',
  height: '34.95px',
  border: `0.94px solid ${theme.palette.info.dark}`,
  margin: '5px auto',
  '& .ant-picker-input > input': {
    color: theme.palette.info.light,
    fontFamily: 'Rubik',
    fontSize: '13.22px',
    fontWeight: 700,
    lineHeight: '21px',
    textAlign: 'left',
  },
  '& .ant-picker-suffix': {
    color: theme.palette.info.light
  },
  '& .ant-picker-input:hover .ant-picker-clear': {
    opacity: 1,
    display: 'none !important'
  },
  '& .ant-picker-focused': {
    boxShadow: 'none'
  }
}))

const TopMenuOption = styled('span')(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '18px',
  textAlign: 'center',
  marginBottom: "10px",
  padding: "10px",
  borderBottom: `2px solid ${theme.palette.info.contrastText}`,
  color: theme.palette.info.light,
  width: "16.67%"
}))

const formats = [
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color"
];
// Customizable Area End
