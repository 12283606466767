import React from "react";

// Customizable Area Start
import {
  Box, Button, Typography, IconButton, Grid, Menu, MenuItem, CircularProgress, ButtonGroup, Avatar,
  TextField, Divider, Accordion, AccordionSummary, AccordionDetails, Step, StepLabel, FormControlLabel,
} from "@material-ui/core";
import { styled, ThemeProvider } from "@material-ui/core/styles";
import { ExpandMore, ExpandLess, KeyboardArrowDown } from "@material-ui/icons";
import { ITask, ISubTask } from "./types";
import { FieldModal, ModalHeading, ModalLine, ModalSubHead } from "../../customisableuserprofiles/src/CustomisableUserProfiles.web";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined"
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import HamburgerMenu from "../../hamburgermenu/src/HamburgerMenu.web";
import { BackButton, NextButton, Steppers, darkTheme, lightTheme } from "../../email-account-registration/src/EmailAccountRegistration.web";
import { DesignationField, DotSection, NameField } from "../../notifications/src/Notifications.web";
import { CompanyName, CompanyNameSubHead, PublishDiv, SearchInput } from "../../bulletinboard/src/BulletinBoard.web";
import { Checkbox, DatePicker, Radio, Select } from "antd";
import { InputField } from "../../settings2/src/Settings2.web";
import { calender, deleteIcon, editButton } from './assets'
import { InProgressLabel, AssignedLabel, CompletedLabel } from "../../certificationtracking/src/CertificationTracking.web";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import Loader from "../../../components/src/Loader.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu";
import { AllocateTypeText } from "./TaskList.web";
import { searchWhite } from "../../bulletinboard/src/assets";
import { RadioDiv } from "../../postcreation/src/PostCreation.web";
import { FilterContainer, GridTitle, GridValue, WorkerGrid } from "../../user-profile-basic/src/UserProfileBasicBlock.web";
const ConfigJSON = require('./config.js');
const roleValues = [
  { "label": "Worker", "value": "student" },
  { "label": "Instructor", "value": "educator" },
  { "label": "Manager", "value": "supervisor/manager" }
]
import { AvatarGroup } from "@material-ui/lab";
// Customizable Area End

import TaskController, { Props, configJSON } from "./TaskController";

// Customizable Area Start
// Customizable Area End

export default class Task extends TaskController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderField = (item: ISubTask, index: number) => (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <SubTaskText
        data-test-id="txtSubTask"
        placeholder="Enter sub-task Name"
        onChange={(event) => this.changeSubTask(event.target.value, index)}
        onKeyPress={this.handleFocus}
        value={this.state.subTask[index].name}
      />
      <div style={{ display: "flex", alignItems: "center" }}>
        <AddNewButton data-test-id="btnAssignCourse " variant="text">Assign Course</AddNewButton>
        <img data-test-id="removeTextField" src={deleteIcon} onClick={() => this.removeField(index, item)} />
      </div>
    </div>
  )

  getFields = () => {
    if (this.state.selectedEmployeesIds.length === 0) {
      return <AllocateTypeText>Choose Task Recipient</AllocateTypeText>
    } else {
      return <AvatarGroup max={20}>
        {this.state.companyEmployee.map((item) =>
          this.state.selectedEmployeesIds.includes(Number(item.attributes.account_id)) &&
          <Avatar
            alt={item.attributes?.preferred_name?.toString()}
            src={item.attributes?.image?.toString()}
            style={{ width: "28px", height: "28px", marginTop: "1px" }}
          />
        )}
      </AvatarGroup>
    }
  }

  AddTaskModal = () => (
    <FieldModal
      data-test-id='replyModal'
      open={this.state.isVisibleModal}
      onCancel={this.showAddModal}
      title={
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <ModalHeading variant="h5">My Task</ModalHeading>
          <ModalLine style={{ width: '32%' }} />
          <ModalSubHead variant="subtitle2">{this.getSubHeading()}Task</ModalSubHead>
          <ModalLine style={{ width: '32%' }} />
        </div>}
      centered
      closable={true}
      footer={null}
      destroyOnClose
      width={742}
      style={{ paddingBottom: '15px', padding: '20px' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '20px' }}>
        <Steppers
          activeStep={this.state.activeStep}
          alternativeLabel
          style={{ margin: 0, padding: 0, background: "transparent", display: "flex", justifyContent: "space-between", marginBottom: "15px" }}
        >
          {ConfigJSON.steps.map((label: string) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Steppers>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar alt="Remy Sharp" src={this.state.userProfile.attributes.image} />
            <div style={{ marginLeft: '10px' }}>
              <NameField style={{ fontSize: '14px' }}>{this.state.userProfile.attributes.preferred_name}</NameField>
              {!this.state.activeAssign ? <DesignationField>{this.state.userProfile.attributes.role_id}</DesignationField>
                : <PublishDiv data-test-id="selectCompaniesModal" onClick={this.openCompaniesModal} style={{ height: "32px", borderColor: this.state.recipientError ? "#DC2626" : "" }}>
                  {this.getFields()}
                  <KeyboardArrowDown />
                </PublishDiv>}
            </div>
          </div>
          <Radio.Group
            data-test-id="radioAssign"
            value={this.state.activeAssign}
            onChange={this.activeAssignTask}
          >
            <FormControlLabel value={true} control={<Radio />} label="Assign Task" />
          </Radio.Group>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', marginLeft: "20px", marginTop: "10px", alignItems: "center" }}>
        <CardSubTitle style={{ fontSize: '20px' }}>Due Date*</CardSubTitle>
        <div style={{ display: 'flex' }}>
          <DateField
            data-test-id='dueDate'
            placement="bottomRight"
            format="MMM DD,YYYY"
            showToday={false}
            onChange={(event) => this.setState({ taskDueDate: String(event) })}
            disabledDate={(event) => event.valueOf() < Date.now()}
            suffixIcon={<img src={calender} />}
            defaultValue={moment(this.state.taskDueDate?.toString())}
          />
        </div>
      </div>

      {!this.state.enableSubTask ?
        <>
          <Box sx={{ padding: "10px 10px 0px 20px" }}>
            <SideTitle>
              Task Title*
            </SideTitle>
            <InputField
              data-test-id="inputTitle"
              type="text"
              placeholder="Type here your task title"
              fullWidth
              value={this.state.title}
              onChange={(event) => this.handleInputTitle(event.target.value)}
            />
          </Box>
          <Box sx={{ padding: "10px 10px 0px 20px" }}>
            <SideTitle>
              Task Description*
            </SideTitle>
            <TaskDescriptionField
              data-test-id="inputDescription"
              multiline
              fullWidth
              minRows={4}
              inputProps={{ maxLength: 100 }}
              onChange={(event) => this.handleInputDescription(event.target.value)}
              placeholder="Type here your task description"
              value={this.state.description}
            />
            <CountChar>{this.state.description.length}/100</CountChar>
          </Box>
          <CheckLine style={{ padding: "10px 10px 0px 20px" }}>
            <TermsChecked
              data-test-id="checkImportant"
              onChange={(event) => this.checkImportant(event)}
              checked={this.state.importantCheck}
            />
            <MarkImportantTxt>Mark as important</MarkImportantTxt>
          </CheckLine>
        </>
        :
        <div className="scrollModal">
          {this.state.subTask.map((item, index) => this.renderField(item, index))}
          <AddNewButton data-test-id="btnAddNewTask" variant="text" onClick={this.addSubTask}>Add New Sub-Task</AddNewButton>
        </div>
      }
      <BackButton
        onClick={this.showAddModal}
        style={{ marginLeft: '20px' }}
      >
        {this.state.enableSubTask ? "Back" : "Cancel"}
      </BackButton>
      <NextButton
        data-test-id="enableAddSubTask"
        style={{ width: '65%', marginLeft: '10px' }}
        onClick={this.selectOperation}
        disabled={!this.state.title || !this.state.description}
      >
        {this.getButtonText()}
      </NextButton>
      <ToastContainer />
    </FieldModal >
  )

  getSubHeading = () => {
    if (this.state.enableSubTask) {
      return "Allocate "
    } else if (this.state.editMode) {
      return "Edit "
    } else {
      return "Add "
    }
  }

  getButtonText = () => {
    if (this.state.enableSubTask) {
      if (this.state.editMode) {
        return "Save"
      } else {
        return 'Publish Task'
      }
    } else {
      return 'Next'
    }
  }

  selectOperation = () => {
    if (this.state.enableSubTask) {
      if (this.state.editMode) {
        this.editTask(String(this.state.selectedId))
      } else {
        this.addTask()
      }
    } else {
      this.goToSubTask()
    }
  }

  renderCardSection = (item: ITask) => (
    <CardSection style={{ borderColor: item.attributes.is_important ? "#B00606" : "" }}>
      <CardHeader>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <CardTitle>{item.attributes.title}</CardTitle>
          <CardSubTitle>{item.attributes.description}</CardSubTitle>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress color="primary" variant="determinate" className="top-circle" value={item.attributes.completion_percentage} style={{ position: 'relative', left: '40', zIndex: 1 }} />
            <CircularProgress color="secondary" variant="determinate" className="bottom-circle" value={100} />
            <Box
              sx={{
                left: 40,
                top: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                position: 'absolute',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ProgressPercentText variant="caption">{`${item.attributes.completion_percentage.toFixed(0)}%`}</ProgressPercentText>
            </Box>
          </Box>

          <DotSection>
            <IconButton
              aria-controls="menu-appbar"
              aria-haspopup="true"
              aria-label="account of current user"
              onClick={(event) => this.handleOptionMenu(event, item)}
              data-test-id="openOption"
              color="inherit"
            >
              <MoreVertOutlinedIcon />
            </IconButton>
            <OptionMenu
              id="menu-appbar"
              anchorEl={this.state.anchorOpen}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              elevation={0}
              open={Boolean(this.state.anchorOpen)}
              onClose={() => this.setState({ anchorOpen: null })}
              style={{ top: 30 }}
            >
              <MenuItem data-test-id='editTask' onClick={this.showEditModal}>
                <img height={18} width={18} src={editButton} style={{ marginRight: 10 }} />
                <EditOption>{configJSON.editTask}</EditOption>
              </MenuItem>

              <MenuItem data-test-id='deleteTask' onClick={() => { this.deleteTask(String(item.attributes.id)) }}>
                <img height={18} width={18} src={deleteIcon} style={{ marginRight: 10 }} />
                <Typography style={{ fontSize: 14, color: '#DC2626' }}>{configJSON.deleteTask}</Typography>
              </MenuItem>
              <Divider />
              <MenuItem>
                <Button
                  variant="text"
                  endIcon={<ArrowForwardIosIcon style={{ marginLeft: '80px', width: "14px" }} />}
                  style={{ fontSize: 14, color: '#1A469C', width: '100%', textTransform: 'capitalize', fontWeight: 400 }}
                  data-test-id='markTask'
                  aria-controls="menu-subOption"
                  aria-haspopup="true"
                  aria-label="Mark Task"
                  color="inherit"
                  onClick={(event) => this.handleSubOptionMenu(event, item.attributes.id)}
                >
                  {configJSON.markAs}
                </Button>
                <OptionMenu
                  id="menu-subOption"
                  anchorEl={this.state.anchorSubOpen}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  elevation={0}
                  open={Boolean(this.state.anchorSubOpen)}
                  onClose={() => this.setState({ anchorSubOpen: null })}
                  style={{ top: 10, left: 60 }}
                  getContentAnchorEl={null}
                >
                  {this.state.selectedItem.attributes.status !== 'in_progress' && <MenuItem data-test-id='inProgressTask' onClick={() => this.changeStatus(item, 'in_progress')}>
                    <MenuText>In Progress</MenuText>
                  </MenuItem>}
                  {(this.state.selectedItem.attributes.status !== 'completed' && this.state.selectedItem.attributes.completion_percentage === 100) && <MenuItem data-test-id='CompletedTask' onClick={() => this.changeStatus(item, 'completed')}>
                    <MenuText>Completed</MenuText>
                  </MenuItem>}
                  {this.state.selectedItem.attributes.status !== 'important' && <MenuItem data-test-id='importantTask' onClick={() => this.changeStatus(item, 'important')}>
                    <MenuText>Important</MenuText>
                  </MenuItem>}
                  {this.state.selectedItem.attributes.status !== 'cancelled' && <MenuItem data-test-id='CancelledTask' onClick={() => this.changeStatus(item, 'cancelled')}>
                    <MenuText>Cancelled</MenuText>
                  </MenuItem>}
                  {this.state.selectedItem.attributes.status !== 'in_review' && <MenuItem data-test-id='InReviewTask' onClick={() => this.changeStatus(item, 'in_review')}>
                    <MenuText>In Review</MenuText>
                  </MenuItem>}
                  {this.state.selectedItem.attributes.status !== 'on_hold' && <MenuItem data-test-id='OnHoldTask' onClick={() => this.changeStatus(item, 'on_hold')}>
                    <MenuText>On Hold</MenuText>
                  </MenuItem>}
                </OptionMenu>
              </MenuItem>
            </OptionMenu>
          </DotSection>
        </div>
      </CardHeader>
      <CardBody>
        {item.attributes.task_lists?.slice(0, 6)?.map((item: ISubTask) => <CheckLine>
          <TermsChecked
            data-test-id="changeTaskProgress"
            checked={item.is_completed}
            onChange={(event) => this.changeTaskProgress(event, item)}
          />
          <CheckText>{item.name}</CheckText>
        </CheckLine>)}
        <Accordion expanded={item.attributes.is_open}>
          {item.attributes.is_open && <AccordionDetails>
            <div style={{ display: 'flex', flexDirection: "column" }}>
              {item.attributes.task_lists?.slice(6).map((item: ISubTask) => <CheckLine>
                <TermsChecked
                  data-test-id="changeTaskProgress"
                  checked={item.is_completed}
                  onChange={(event) => this.changeTaskProgress(event, item)}
                /><CheckText>{item.name}</CheckText>
              </CheckLine>)}
            </div>
          </AccordionDetails>}
          <AccordionSummary>
            <div style={{ display: "flex", flexDirection: 'row', justifyContent: "space-between", width: '100%' }}>
              <div style={{ display: "flex", flexDirection: 'row' }}>
                <DateText>
                  Due Date:
                </DateText>
                {this.showMoreDetailsIcon(item)}
              </div>
              <div style={{ display: 'flex', alignItems: "center" }}>
                {item.attributes.is_important && <AssignedLabel>IMPORTANT!</AssignedLabel>}

                {(item.attributes.task_lists!.length > 6) &&
                  <div
                    data-test-id="expandAccordion"
                    onClick={() => this.handleAccordion(item)}
                  >
                    {item.attributes.is_open ? <ExpandLess /> : <ExpandMore />}
                  </div>}
              </div>

            </div>
          </AccordionSummary>
        </Accordion>
      </CardBody>
    </CardSection>
  )

  showMoreDetailsIcon = (item: ITask) => {
    let value = this.calculateWeek(String(item.attributes.due_date))
    if (value < 1) {
      return <AssignedLabel>{moment.utc(item.attributes.due_date).format('MM/DD/YYYY h:mm A')}</AssignedLabel>
    } else if (value >= 1 && value < 2) {
      return <InProgressLabel>{moment.utc(item.attributes.due_date).format('MM/DD/YYYY h:mm A')}</InProgressLabel>
    } else {
      return <CompletedLabel>{moment.utc(item.attributes.due_date).format('MM/DD/YYYY h:mm A')}</CompletedLabel>
    }
  }

  allCompaniesModal = () => (
    <FieldModal
      open={this.state.companiesModal}
      onCancel={this.closeCompaniesModal}
      title={
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <ModalHeading variant="h5">Task List</ModalHeading>
          <ModalLine style={{ width: '160px' }} />
          <ModalSubHead variant="subtitle2">Allocate Task</ModalSubHead>
          <ModalLine style={{ width: '160px' }} />
        </div >}
      footer={null}
      centered
      closable={true}
      destroyOnClose
      style={{ paddingBottom: '40px' }}
      width={742}
    >
      <ModalHeading variant="h5">Task Recipient</ModalHeading>
      <SearchInput
        data-test-id="searchCompanyKey"
        startAdornment={<img src={searchWhite} style={{ marginLeft: "10px" }} />}
        placeholder="Search"
        onChange={this.changeSearchKey}
      />
      <div className="scrollModal" style={{ maxHeight: "210", overflowY: 'scroll', paddingRight: "10px" }}>
        <Radio.Group
          data-test-id='selectCompany'
          value={this.state.selectedCompany}
          onChange={this.selectCompany}
          style={{ width: "96%" }}
        >
          {this.state.companiesNames?.map((item) => (
            <FormControlLabel
              value={item.attributes.company_name}
              control={<Radio />}
              label={<RadioDiv>
                <Avatar style={{ marginRight: "10px", width: "21px", height: "23px" }} />
                <div>
                  <CompanyName>company Name</CompanyName>
                  <CompanyNameSubHead>{item.attributes.company_name}</CompanyNameSubHead>
                </div>
              </RadioDiv>}
              labelPlacement="start"
              style={{ borderBottom: "none" }}
            />
          ))}
        </Radio.Group>
      </div>
      <BackButton data-test-id="closeSpecificModal" onClick={this.closeCompaniesModal} style={{ marginLeft: "8px" }}>
        Back
      </BackButton>
      <NextButton
        data-test-id="openEmployeeModal"
        style={{ width: '65%', marginLeft: '10px' }}
        onClick={this.openCompanyEmployeeModal}
        disabled={this.state.selectedCompany === ""}
      >
        Next
      </NextButton>
    </FieldModal >
  )

  employeeCompaniesModal = () => (
    <FieldModal
      open={this.state.companyEmployeeModal}
      onCancel={this.closeCompanyEmployeeModal}
      title={
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <ModalHeading variant="h5">Task List</ModalHeading>
          <ModalLine style={{ width: '160px' }} />
          <ModalSubHead variant="subtitle2">Allocate Task</ModalSubHead>
          <ModalLine style={{ width: '160px' }} />
        </div >}
      centered
      footer={null}
      destroyOnClose
      closable={true}
      width={742}
      style={{ paddingBottom: '40px' }}
    >
      <div style={{ display: "flex" }}>
        <ModalHeading variant="h5">Task Recipient :</ModalHeading>
        <ModalSubHead variant="subtitle1">{this.state.selectedCompany}</ModalSubHead>
      </div>
      <FilterContainer>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ModalSubHead variant="body1"> Filter by:</ModalSubHead>
          <Select
            data-test-id="roleFilter"
            value={this.state.filterRole || undefined}
            onChange={(event) => this.roleFilter(event)}
            placeholder="Role"
            dropdownMatchSelectWidth={false}
            style={{ border: "none", minWidth: "auto", fontSize: "16px" }}
          >
            {roleValues?.map((item) => (
              <Select.Option value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
          <Select
            data-test-id="groupFilter"
            value={this.state.filterGroup || undefined}
            onChange={(event) => this.groupFilter(event)}
            placeholder="Group"
            dropdownMatchSelectWidth={false}
            style={{ border: "none", minWidth: "auto", fontSize: "16px" }}
          >
            {this.state.groupList?.map((item) => (
              <Select.Option value={item.group_name}>
                {item.group_name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <SearchInput
          startAdornment={<img src={searchWhite} style={{ marginLeft: "10px" }} />}
          placeholder="Search"
          style={{ width: "30%", margin: 0 }}
        />
      </FilterContainer>

      <div className="scrollModal" style={{ maxHeight: "210", overflowY: 'scroll', paddingRight: "10px" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {this.state.companyEmployee?.map((item) => (
            <WorkerGrid container spacing={1}>
              <Grid item xs={"auto"} style={{ marginRight: "15px", display: "flex", alignItems: "center" }}>
                <TermsChecked
                  checked={this.state.selectedEmployeesIds.includes(Number(item.attributes.account_id))}
                  data-test-id="selectEmployeeId"
                  onChange={(event) => this.selectEmployee(event, Number(item.attributes.account_id))}
                />
              </Grid>
              <Grid item xs={"auto"} style={{ marginRight: "15px" }}>
                <GridTitle>ID</GridTitle>
                <GridValue>{item?.attributes?.account_id}</GridValue>
              </Grid>
              <Grid item xs={3}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Avatar
                    src={item.attributes?.image?.toString()}
                    style={{
                      width: "34px",
                      height: "34px",
                      marginRight: "5px",
                      marginTop: "5px",
                      borderRadius: "50%",
                    }}
                    alt={item.attributes?.preferred_name?.toString()}
                  />
                  <div>
                    <GridTitle>Name</GridTitle>
                    <GridValue>
                      {item.attributes?.preferred_name}
                    </GridValue>
                  </div>
                </div>
              </Grid>
              <Grid item xs={2}>
                <GridTitle>Role</GridTitle>
                <GridValue>{item.attributes?.role_id}</GridValue>
              </Grid>
              <Grid item xs={2}>
                <GridTitle>Dep.</GridTitle>
                <GridValue>{item.attributes?.company_name}</GridValue>
              </Grid>
              <Grid item xs={3}>
                <GridTitle>Group Name</GridTitle>
                <GridValue>{item.attributes?.group_name}</GridValue>
              </Grid>
            </WorkerGrid>
          ))}
        </div>
      </div>
      <BackButton data-test-id="closeCompanyEmployeeModal" onClick={this.closeCompanyEmployeeModal} style={{ marginLeft: "8px" }}>
        Back
      </BackButton>
      <NextButton
        data-test-id="finalizeEmployee"
        style={{ width: '65%', marginLeft: '10px' }}
        onClick={() => this.setState({ companyEmployeeModal: false, companiesModal: false, recipientError: false })}
        disabled={this.state.selectedEmployeesIds.length === 0}
      >
        Finalize & Send
      </NextButton>
    </FieldModal >
  )

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={this.state.selectedTheme ? darkTheme : lightTheme}>
        <Loader loading={this.state.isLoading} />
        <HamburgerMenu
          navigation={this.props.navigation}
          handleMenu={this.toggleMenu}
          open={this.state.openMenu}
          themeDark={this.state.selectedTheme}
          handleTheme={this.changeTheme}
        />
        <NavigationMenu
          id=""
          navigation={this.props.navigation}
          theme={this.state.selectedTheme}
          open={this.state.openMenu}
        />
        <OuterContainer style={{ paddingLeft: this.state.openMenu ? 273 : 140, paddingRight: 48, transition: 'all 300ms linear' }}>
          <BodyHeader>
            <div>
              <TaskListTitle>My Task List</TaskListTitle>
            </div>
            <AddTaskButton
              data-test-id="btnAddTaskModal"
              startIcon={<AddBoxOutlinedIcon />}
              onClick={this.showAddModal}
            >
              Add Task
            </AddTaskButton>
          </BodyHeader>
          <Grid container>
            <LeftGrid item xs={"auto"}>
              <ButtonGroup>
                <Button
                  data-test-id='inProgressTask'
                  variant="text"
                  onClick={() => this.changeInprogress(true, false, 'in_progress')}
                  className={this.state.status === 'in_progress' ? "selectedButton" : ""}
                >
                  In Progress
                </Button>
                <Button
                  data-test-id='completedTask'
                  variant="text"
                  onClick={() => this.changeInprogress(false, true, 'completed')}
                  className={this.state.status === 'completed' ? "selectedButton" : ""}
                >
                  Completed
                </Button>
              </ButtonGroup>
              <Box style={{ height: '90%' }} className="scrollDiv">
                {this.state.taskList?.map((item) => (
                  this.renderCardSection(item)
                ))}
              </Box>
            </LeftGrid>
            <LeftGrid item xs={"auto"}>
              <ButtonGroup>
                <Button
                  data-test-id='inReviewTask'
                  variant="text"
                  onClick={() => this.changeReviewStatus(true, false, false, 'in_review')}
                  className={this.state.status1 === 'in_review' ? "selectedButton" : ""}
                >
                  In Review
                </Button>
                <Button
                  data-test-id='onHoldTask'
                  variant="text"
                  onClick={() => this.changeReviewStatus(false, true, false, 'on_hold')}
                  className={this.state.status1 === 'on_hold' ? "selectedButton" : ""}
                >
                  On Hold
                </Button>
                <Button
                  data-test-id='cancelledTask'
                  variant="text"
                  onClick={() => this.changeReviewStatus(false, false, true, 'cancelled')}
                  className={this.state.status1 === 'cancelled' ? "selectedButton" : ""}
                >
                  Cancelled
                </Button>
              </ButtonGroup>
              <Box className="scrollDiv" style={{ height: '90%' }}>
                {this.state.taskList1?.map((item) => (
                  this.renderCardSection(item)
                ))}
              </Box>
            </LeftGrid>
          </Grid>
          {this.AddTaskModal()}
          {this.allCompaniesModal()}
          {this.employeeCompaniesModal()}
          <ToastContainer
            containerId='B'
            closeButton={false}
            style={{ width: "463px", height: "42px" }}
            theme={this.state.selectedTheme ? "dark" : "light"}
          />
        </OuterContainer>
      </ThemeProvider >
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const OuterContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
  "& .Toastify__toast": {
    minHeight: '42px',
    padding: '0px 8px',
    color: `${theme.palette.info.main} !important`,
    background: `${theme.palette.primary.main} !important`,
    "& svg": {
      fill: theme.palette.warning.light
    }
  },
  "& .accordionStyle": {
    marginLeft: '10px',
    borderRadius: "18px",
    background: theme.palette.action.focus,
    "& .recharts-text": {
      fontSize: '10px',
      fontFamily: 'Rubik',
      lineHeight: '11.85px',
      fontWeight: 400,
      color: theme.palette.info.main
    },
    '& .MuiAccordion-root::before': {
      backgroundColor: 'transparent',
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
      background: theme.palette.background.default
    },
    '& .recharts-cartesian-axis-line': {
      display: 'none'
    },
    " & .recharts-bar-background-rectangle": {
      fill: theme.palette.warning.contrastText
    },
  },
  "& .ant-picker-cell-disabled::before": {
    background: "transparent"
  }
}))

const TaskListTitle = styled(Typography)(({ theme }) => ({
  lineHeight: '30px',
  fontSize: '30px',
  color: theme.palette.info.main,
  fontWeight: 500,
}))

const BodyHeader = styled(Box)(({ theme }) => ({
  width: '100%',
  justifyContent: 'space-between',
  display: 'flex',
  margin: '10px',
  background: theme.palette.action.focus,
  height: "100px",
  borderRadius: "18px",
  marginBottom: '15px',
  padding: '20px',
  alignItems: 'center'
}))

const AddTaskButton = styled(Button)(({ theme }) => ({
  width: '100%',
  maxWidth: '135px',
  height: '44px',
  border: `1px solid ${theme.palette.info.contrastText}`,
  borderRadius: '30px',
  textTransform: 'capitalize',
  padding: '10px 16px',
  fontSize: '16px',
  fontWeight: 500,
  color: theme.palette.info.contrastText,
  lineHeight: '24px',
  '& .MuiSvgIcon-root': {
    fill: theme.palette.info.contrastText
  }
}))

const CardSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: "96%",
  borderRadius: "6px",
  padding: '8px 12px 8px 16px',
  borderLeft: `4px solid ${theme.palette.action.selected}`,
  marginBottom: "10px",
  background: theme.palette.background.default,
  '& .MuiPaper-root': {
    background: theme.palette.background.default,
  },
  '& .MuiAccordion-root.Mui-expanded': {
    margin: 0
  },
  '& .MuiAccordionDetails-root': {
    padding: 0
  },
  '& .MuiPaper-elevation1': {
    boxShadow: 'none'
  },
  '& .MuiAccordion-root::before': {
    backgroundColor: "transparent"
  },
  '& .MuiSvgIcon-root': {
    fill: theme.palette.info.contrastText
  },
  '& .MuiAccordionSummary-root': {
    padding: 0
  }
}))

const CardHeader = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  "& .MuiCircularProgress-colorSecondary": {
    color: theme.palette.background.paper
  },
  "& .MuiCircularProgress-colorPrimary": {
    color: theme.palette.info.contrastText
  }
}))

const CardTitle = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: '24px',
  color: theme.palette.secondary.main
}))

export const AccordionTitle = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontWeight: 500,
  lineHeight: '26px',
  color: theme.palette.info.main
}))

const CardSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: '14px',
  color: theme.palette.info.light
}))

const CardBody = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: 'column'
}))

export const CheckText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  marginLeft: '5px',
  marginTop: '4px'
}))

export const TermsChecked = styled(Checkbox)(({ theme }) => ({
  "& .ant-checkbox-inner": {
    borderRadius: '6px',
    height: '20px',
    width: '20px',
    top: 1,
    border: `1px solid ${theme.palette.info.light}`,
    background: theme.palette.primary.main
  },
  '& .ant-checkbox-input:hover': {
    borderRadius: '6px',
    borderColor: `${theme.palette.info.main}} !important`
  },
  '& .ant-checkbox-checked .ant-checkbox-inner': {
    background: theme.palette.info.contrastText
  },
  '& .ant-checkbox-checked .ant-checkbox-inner:hover': {
    borderRadius: '6px',
    borderColor: `${theme.palette.info.main}} !important`
  },
  '& .ant-checkbox-checked .ant-checkbox-inner::after': {
    borderColor: theme.palette.primary.main
  }
}))

export const CheckLine = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: "row"
}))

const LeftGrid = styled(Grid)(({ theme }) => ({
  marginLeft: "10px",
  background: theme.palette.action.focus,
  height: '80vh',
  width: "48%",
  borderRadius: "18px",
  padding: '20px',
  "& .MuiButtonGroup-root": {
    borderRadius: '50px',
    background: theme.palette.background.default,
    width: "100%",
    marginBottom: '10px'
  },
  "& .MuiButtonGroup-grouped": {
    fontWeight: 500,
    textTransform: 'capitalize',
    lineHeight: '22px',
    fontsize: '14px',
    borderRadius: '30px',
    color: theme.palette.info.light,
    margin: '5px',
    padding: '5px',
    width: "100%"
  },
  "& .selectedButton": {
    background: theme.palette.background.paper,
    color: theme.palette.info.contrastText,
  },
  "& .recharts-text": {
    fontSize: '10px',
    fontFamily: 'Rubik',
    lineHeight: '11.85px',
    fontWeight: 400,
    color: theme.palette.info.main
  },
  '& .MuiAccordion-root::before': {
    backgroundColor: 'transparent',
  },
  "& .MuiPaper-elevation1": {
    boxShadow: "none",
    background: theme.palette.background.default
  },
  '& .recharts-cartesian-axis-line': {
    display: 'none'
  },
  "& .scrollDiv": {
    overflowY: 'scroll'
  },
  "& .scrollDiv::-webkit-scrollbar-track": {
    background: 'transparent',
    borderRadius: '30px 30px 90px 30px',
    border: `1px solid ${theme.palette.info.dark}`,
  },
  "& .scrollDiv::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #94A3B8',
    borderRadius: '30px 30px 90px 30px',
    width: '13px',
    height: '43px',
  },
  "& .scrollDiv::-webkit-scrollbar": {
    width: '13px',
    borderRadius: '30px 30px 90px 30px',
  },
}))

const DateText = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.light,
  fontFamily: 'Rubik',
  fontSize: '10px',
  fontWeight: 400,
  lineHeight: '19px',
  marginTop: '4px',
  marginRight: "10px"
}))

export const TaskDescriptionField = styled(TextField)(({ theme }) => ({
  borderRadius: '8px',
  fontFamily: 'Rubik',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  textAlign: 'left',
  color: theme.palette.info.main,
  background: theme.palette.background.default,
  "& ::placeholder": {
    color: theme.palette.info.light
  },
  "& .MuiInputBase-input": {
    margin: 0
  },
  "& .MuiInputBase-multiline": {
    padding: 0
  }
}))

export const SubTaskText = styled('input')(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '16px',
  height: '44px',
  fontWeight: 400,
  lineHeight: '24px',
  color: theme.palette.info.main,
  background: 'transparent',
  padding: '1px 12px',
  border: "none",
  width: "100%",
  margin: "10px 20px",
  "& ::placeholder": {
    color: theme.palette.info.light
  },
  "& ::selection": {
    background: 'transparent'
  }
}))

const AddNewButton = styled(Button)(({ theme }) => ({
  height: '22px',
  width: "130px",
  textTransform: "capitalize",
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "22px",
  float: "right",
  color: theme.palette.info.contrastText
}))

const ProgressPercentText = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: '8px',
  fontWeight: 400,
  color: theme.palette.secondary.main
}))

const OptionMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    boxShadow: `rgba(0, 0, 0, 0.15) 0px 2px 4px 0px`,
    backgroundColor: theme.palette.background.default,
    width: '200px',
    borderRadius: '18px',
    border: `1px solid ${theme.palette.action.focus}`
  },
  '& .MuiListItem-button:hover': {
    backgroundColor: theme.palette.warning.main
  },
  "& .MuiList-padding": {
    paddingBottom: 0
  },
  "& .MuiButton-text": {
    padding: 0
  },
  "& .MuiButton-root:hover": {
    backgroundColor: "transparent"
  }
}))

const CountChar = styled('span')(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 400,
  color: theme.palette.text.primary,
  textAlign: 'right',
  float: 'right'
}))

export const SideTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '22px',
  color: theme.palette.text.primary
}))

export const MarkImportantTxt = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main,
  fontFamily: 'Rubik',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  marginLeft: '5px',
}))

export const MenuText = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.main,
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
}))


export const DateField = styled(DatePicker)(({ theme }) => ({
  background: 'transparent',
  padding: '10px',
  height: '41.4px',
  border: `none`,
  color: theme.palette.secondary.main,
  fontFamily: 'Rubik',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '28px',
  textAlign: 'right',
  '& .ant-picker-input > input': {
    color: theme.palette.secondary.main,
    fontFamily: 'Rubik',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '28px',
    textAlign: 'center',
  },
  '& .ant-picker-input:hover .ant-picker-clear': {
    opacity: 1,
    display: 'none !important'
  }
}))

const EditOption = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 400,
  color: theme.palette.success.light,
  lineHeight: "22px"
}))
// Customizable Area End
