import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";


// Customizable Area Start

import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";


export interface Props {
  // Customizable Area Start
  titleStyle?: React.CSSProperties;
  subTitleStyle?: React.CSSProperties;
  mainStyle?: React.CSSProperties;
  firstBlockStyle?: React.CSSProperties;
  onCloseMenu?: () => void;
  logOutBlockInnerStyle?: React.CSSProperties;
  logOutBlockContainorStyle?: React.CSSProperties;
  logOutBlock?: () => JSX.Element | React.ReactFragment;
  titleContainorStyle?: React.CSSProperties;
  onLogOut?: () => void;
  navigation:any;
  handleMenu:()=>void;
  open:boolean;
  handleTheme?: () => void;
  themeDark?: boolean;
  settingsOpen?: boolean;
  handleSettings?: ()=> void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  enableField: boolean;

  selectCheckbox: boolean;
  selectedButton: string;
  reminderswitch: boolean;
  loader: boolean;
  open: boolean;
  darkTheme: boolean;
  toggleDrawer: string;
  activePath:string;
  isModalOpen: boolean;
  getRole: string;
  isHovered:string
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

export const colors = {
  darkBlue: '#122247',
  lightGrey: '#95979E',
  blue: '#0028FF',
  white: '#ffffff',
  lightBlue: '#E8F5FE',
  black: '#000000',
  lightBlue1: '#C6E5FD',
  darkblue1: '#2850C4',
  cream: '#F4DCCF',
  lightCream: '#C2B1A5',
  green: '#57C898',
  placeholderTextColor: '#8F92A1',
  grey: '#A9A9A9',
  lightGreen: '#55C699',
  grey1: '#E8E8E8',
  bg_Header: '#0D2564',
  btn_blue: '#0028FF',

  light_blue: '#C7E5FD',
  dark_blue: '#0D2666',

  light_cream: '#F4DDCE',
  light_orange: '#F08A59',
  light_red: '#ED6669',
  light_grey: '#E8E8E8',
  dark_grey: '#414141',
  light_grey_1: '#e0e0e0',
  extra_light_blue: '#D1D6E2',

  lable: '#8F92A1',

  calender_darkBlue: '#0129FF',
  calender_lightBlue: '#E8F5FE',
  calender_lightSkin: '#F4DDCE',
  calender_black: '#000000',
  calender_white: '#ffffff',
  calender_lightBlue1: '#C6E5FD',
  calender_darkBlue1: '#0B2066',
  calender_darkBlue2: '#203BC4',
  calender_cream: '#F4DDCE',
  calender_lightcream: '#C2B1A5',
  calender_lightGray: '#F5F5F5',
  calender_arrowBackGroundColor: '#E9E8ED',
  calender_arrowColor: '#91949E',
  calender_green: '#60CC99'
};

export const configJSON = require("./config");
// Customizable Area End




export default class HamburgerMenuController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  gettingRoleDataAPICallId:any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      enableField: false,
      selectCheckbox: false,
      selectedButton: "Interviews",
      reminderswitch: false,
      loader: false,
      open: false,
      darkTheme:false,
      toggleDrawer:"",
      activePath:"",
      isModalOpen: false,
      getRole:"",
      isHovered:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }
  // Customizable Area End


  async receive(from: string, message: Message) {



    // Customizable Area Start
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const receivedMessage = message.getData(getName(MessageEnum.SessionResponseData));
      this.setState({activePath:receivedMessage?.data})
      setStorageData('activePath',this.state.activePath)
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        if (apiRequestCallId === this.gettingRoleDataAPICallId) {
          this.setState({ getRole: responseJson.role_id });
        }
      }
    }

    // Customizable Area End

  }



  // Customizable Area Start

  async componentDidMount() {
  let route=await getStorageData('route');
  let sessionName:any=sessionStorage.getItem("currentRoute");
  this.setState({activePath:sessionName});
    if(window.location.pathname == "/notificationsetting" || window.location.pathname == "/CustomisableUserProfiles" || window.location.pathname == "/Settings2"){
      this.setState({toggleDrawer:"settingsDrawer"})
    }
    else{
      this.setState({toggleDrawer:"mainDrawer"})
    }
  this.gettingRoleActive();
  }
 
  goToCalenderView = () => {


  };
  goToBulletinBoardPage = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationBulletinBoardMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  
  goToWorkerProgressPage = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationPerformanceTrackerMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  goToCertificateTrackingPage = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationCertificationTrackingMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  goToMyTaskPage = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationTaskMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  goToAllocateTaskPage = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationTaskListMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  goToReferencePage = () => {

  };
  goToNotificationSettings = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationNotificationSettingsMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  goToSettings = (route: string) => {
    sessionStorage.setItem('currentRoute',route)

    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Settings2");
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { data: route });
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  gotoAppearance = () => {
    this.goToSettings('appearance');
  }

  gotoMessage = () => {
    this.goToSettings('message');
  }

  gotoSecurity = () => {
    this.goToSettings('security');
  }

  goToDashboardScreen = ()=> {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'Dashboard');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  goToUserProfileScreen = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationUserProfileBasicBlockMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  goToTeamMemberScreen = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationPtStudentInfoScreenMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  
  goToCustomizableUserProfileScreen = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationCustomisableUserProfilesMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  removeAndLogOut = () => {

  }
  //make active to firestore
  makeActive = (flag: boolean, callback: () => void) => {

  }

  onLogOut = () => {

  }


  handleDrawerClose = () => {
    this.setState({ open: false });
  };
  
  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  handleLogout=()=>{
    this.setState({isModalOpen: !this.state.isModalOpen})
  }

  handleNavigate=(routeName:any,itemRoute:any)=>{
    const method = this[routeName as keyof this] as unknown as () => void;
    if (typeof method === 'function') {
      method.call(this);
    } 
    sessionStorage.setItem('currentRoute',itemRoute)
  }

  handleClose=() => {
    this.setState({isModalOpen: false})
  }

  handleButton=()=>{
    localStorage.removeItem('authToken');
    this.goToLogin()
  }

  gettingRoleActive = () => {
    const header = {
      "Content-Type": configJSON.notificationApiContentType,
      token: localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.gettingRoleDataAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.validateRoleEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSettingsPart=()=>{
    this.setState({ toggleDrawer: "settingsDrawer" })
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'CustomisableUserProfiles');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
    sessionStorage.setItem('currentRoute','CustomisableUserProfiles')
  }

  // Customizable Area End

}
