export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgSmallLogo = require("../assets/newSmallLogo.svg");
export const settingImg = require("../assets/image_alarm_24px.svg");
export const logoutImg = require("../assets/image_alarm_24px (1).svg");
export const activeLogoutImg = require("../assets/active_logoutIcon.svg");

export const editImg = require("../assets/edit.svg");
export const KeyImg = require("../assets/key.svg");
export const activenotificationImg = require("../assets/bell.svg");
export const chatImg = require("../assets/chat.svg");
export const paintImg = require("../assets/paint.svg");
export const Img = require("../assets/profile.svg");
export const fullLogo = require("../assets/newFullLogo.svg");
export const darkLogo = require("../assets/image_ (1).png")
export const rememberIcon = require("../assets/rememberIcon.svg")
export const OverviewIcon = require("../assets/OverviewIcon.svg")
export const activeOverviewIcon = require("../assets/active_overviewIcon.svg")
export const coursesIcon = require("../assets/coursesIcon.svg")
export const completeCourse = require("../assets/completeCourse.svg")
export const certificatesIcon = require("../assets/certificatesIcon.svg")
export const bulletinBoard = require("../assets/bulletinBoard.svg")
export const settingsIcon = require("../assets/settingsIcon.svg")

export const activeMsg = require("../assets/active_msg.svg")
export const activeProfile = require("../assets/active_profile.svg")
export const activeSecurity = require("../assets/active_security.svg")
export const activeAppearnace = require("../assets/active_appearance.svg")
export const notificationImg = require("../assets/notification_img.svg")
export const modalLogoutImg = require("../assets/modalLogout.svg")
export const teamMemberImg = require("../assets/teamMember.svg")
export const FeedbackImg = require("../assets/feedback.svg")
export const stu_EducatorProgressImg = require("../assets/stu_educator.svg")
export const pendingCourseImg = require("../assets/pendingCourse.svg")

export const activePendingCourseImg = require("../assets/active_pendingCourse.svg")
export const activestu_EducatorImg = require("../assets/active_stu_educator.svg")
export const activeTeamMemberImg = require("../assets/active_teamMember.svg")
export const activeBulletinBoard = require("../assets/active_bulletinBoard.svg")
export const activeCertificatesIcon = require("../assets/active_certificatesIcon.svg")
export const activeCompleteCourse = require("../assets/active_completeCourse.svg")
export const activeFeedbackImg = require("../assets/active_feedback.svg")
export const activeRememberIcon = require("../assets/active_rememberIcon.svg")
export const activeCoursesIcon = require("../assets/active_coursesIcon.svg")

export const TaskListIcon = require("../assets/TaskList.svg")
export const mytaskIcon = require("../assets/MyTasks.svg")
export const allocateTaskIcon = require("../assets/AllocateTask.svg")
export const activeReminderIcon = require("../assets/active_reminderIcon.svg")
export const activemyTaskIcon = require("../assets/active_myTask.svg")
export const activeAllocateTaskIcon = require("../assets/active_allocateTask.svg")
export const LibraryIcon = require("../assets/library.svg")
export const activeLibraryIcon = require("../assets/active_library.svg")
export const bulletinIcon = require("../assets/Vector(1).svg")
export const activebulletinIcon = require("../assets/active_vector.svg")
export const lightBulletinIcon = require("../assets/whiteBulletin.svg")
export const lightCourseIcon = require("../assets/WhiteCourseIcon.svg")
export const lightCertificatesIcon = require("../assets/whiteCertificates.svg")
export const lightDocumentIcon = require("../assets/whiteDocumentIcon.svg")
export const lightLibraryIcon = require("../assets/whiteLibrary.svg")
export const lightProgressBarIcon = require("../assets/whiteProgressBar.svg")
export const lightLogoutIcon = require("../assets/whiteLogout.svg")
export const lightReminderIcon = require("../assets/whiteReminder.svg")
export const lightTeamMembersIcon = require("../assets/whiteTeamMembers.svg")
export const lightSettingsIcon = require("../assets/whiteSettings.svg")
export const lightDashboardIcon = require("../assets/whiteDashboard.svg")
export const lightFeedbackIcon = require("../assets/whiteFeedback.svg")


export const icons = {
    calender: 'date-range',
    comment_dots: 'comments',
    business: 'business',
    people: 'people',
    settings: 'settings',
    navigate_next: 'navigate-next',
    logout: 'input',
    chevron_left: 'chevron-left',
    chevron_right: 'chevron-right',
    search: 'search',
    playlist_add: 'playlist-add',
    edit_calendar: 'event-available',
    videocam: 'videocam',
    location_on: 'location-on',
    more_vert: 'more-vert',
    home: 'home',
    call: 'call',
    account_box: 'account-box',
    email: 'email',
    work: 'work',
    plus: 'add',
    photo_camera: 'photo-camera',
    notifications: 'notifications',
    lock: 'lock',
    border_color: 'border-color',
    delete: 'delete',
    dollar: 'dollar',
    calendar: 'calendar',
  
    phone: 'phone',
    contact: 'contacts',
    back: 'chevron-left',
    edit: 'edit',
    check: 'check',
    share: 'share',
  
    close: 'close',
  
    date_range: 'date-range',
  
    add_circle_outline: 'add-circle-outline',
    play_arrow: 'play-arrow',
  
    cached: 'cached',
    loop: 'loop',
    flash_off: 'flash-off',
    flash_on: 'flash-on',
  
    expand_more: 'expand-more'
  };