import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start
interface ProfileAttributes {
  id: number;
  first_name: string | null;
  last_name: string | null;
  email: string;
  date_of_birth: string;
  preferred_name: string;
  role_id: string;
  full_phone_number: string;
  employee_number: string | null;
  location: string | null;
  company_reference: string | null;
  completed_course: string | null;
  account_id: number;
  image: string | null;
  group_id: number;
  group_name: string;
  company_id: number;
  company_name: string;
  phone_number_verified: boolean;
  selected_theme: string;
  font_size: string;
}

interface Profile {
  id: string;
  type: "profile";
  attributes: ProfileAttributes;
}
interface ProfileData  {
  id: string;
  type: string;
  attributes: ProfileAttributes;
};
// Customizable Area End

export const configJSON = require('./config');

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  open:boolean;
  darkTheme:boolean;
  openChatModal:boolean;
  profileData:ProfileData;
  openFeedbackModal:boolean;
  openDirectMessageModal:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PerformanceTrackerController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiProfileGETCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: '',
      open:false,
      darkTheme:false,
      openChatModal:false,
      profileData: {
        id: "",
        type: "profile",
        attributes: {
          id: 0,
          first_name: null,
          last_name: null,
          email: "",
          date_of_birth: "",
          preferred_name: "",
          role_id: "",
          full_phone_number: "",
          employee_number: null,
          location: null,
          company_reference: null,
          completed_course: null,
          account_id: 0,
          image: null,
          group_id: 0,
          group_name: "",
          company_id: 0,
          company_name: "",
          phone_number_verified: false,
          selected_theme: "",
          font_size: "",
        },
      },
      openFeedbackModal:false,
      openDirectMessageModal:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage),
    );
    this.send(message);
  };

  async componentDidMount(): Promise<void> {
    super.componentDidMount();
    this.getToken();

    this.getUserProfileData();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    let menuState: any = localStorage.getItem('open');
    if (menuState != null) {
      this.setState({ open: JSON.parse(menuState) })
    }
  }
  getUserProfileData=()=>{
    const webHeader = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken")
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiProfileGETCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileGetURL
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  }
  navigateToViewCourses = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'PtCoursesScreen',
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  navigateToViewStudents = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'PtStudentRankingScreen',
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
       if (webApiRequestCallId === this.apiProfileGETCallId) {
          this.setState({
            profileData: webResponseJson.data,
          });
        } 
      } 
    }
  }
  handleMenu=()=>{
    this.setState({open:!this.state.open});
    localStorage.setItem('open',JSON.stringify(!this.state.open));
  }
  
  handleTheme=()=>{
    this.setState({darkTheme:!this.state.darkTheme})
  }
  closeChatModal=()=>{
    this.setState({
      openChatModal:false
    })
  }
  handleChatModal=()=>{
    this.setState({
      openChatModal:true
    })
  }
  
  closeFeedbackModal=()=>{
    this.setState({
      openFeedbackModal:false
    })
  }
  handleFeedbackModal=()=>{
    this.setState({
      openFeedbackModal:true
    })
  }
  closeDMModal=()=>{
    this.setState({
      openDirectMessageModal:false
    })
  }
  handleDMModal=()=>{
    this.setState({
      openDirectMessageModal:true
    })
  }


  // Customizable Area End
}
